<template>
  <div>
    <div class="pc">
      <div class="head">
        <div class="head_box01">
          <div class="title_app pcOnly">郑州中昆资源管理系统</div>

          <div class="login_box">
            <div
              class="login"
              @click="opeLloginWindow('open')"
              v-show="$store.state.userName.length == 0"
            >
              登录
            </div>
            <div class="pcOnly">{{ $store.state.userName }}</div>
          </div>
        </div>
      </div>
      <div class="container">
        <router-view> </router-view>
      </div>
    </div>
    <div class="loginWindow">
      <div class="box">
        <div class="title">用户名：</div>
        <input type="text" v-model="dataToSql.para_02" />
        <div class="title">密码：</div>
        <input type="text" v-model="dataToSql.para_03" @keyup.enter="login()" />
        <div class="btn_box">
          <div class="btn" @click="opeLloginWindow('close')">取消</div>
          <div class="btn" @click="login()">登录</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <a href="https://beian.miit.gov.cn/">豫ICP备12010654号-3</a>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import api from "@/api/index";
import axios from "axios";
import store from "./store";
export default {
  name: "App",
  components: {
    Price: () => import("@/components/Price"),
    Stocks: () => import("@/components/Stocks"),
    PriceRecordInsect: () => import("@/components/PriceRecordInsect"),
    Login: () => import("@/components/Login"),
    PriceRecord: () => import("@/components/PriceRecord"),
  },
  setup() {
    const $router = new useRouter();
    let baseImg = "";

    let dataToSql = reactive({
      id: null,
      para_01: "用户",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
    });

    const opeLloginWindow = (para) => {
      let window = document.querySelector(".loginWindow");
      if (para == "open") {
        window.style.display = "block";
      } else if (para == "close") {
        window.style.display = "none";
      }
    };

    const login = () => {
      if (dataToSql.para_02.trim() == "") {
        return alert("请输入用户名");
      }
      if (dataToSql.para_03.trim() == "") {
        return alert("请输入密码");
      }
      axiosToSql();
    };

    const axiosToSql = () => {
      axios({
        method: "post",
        url: api.host + api.UniversalUserData,
        headers: "multipart/form-data",
        data: dataToSql,
      }).then((res) => {
        if (res.data.msg == "无此用户") {
          alert("无此用户");
        } else {
          // 从后端返回的密码
          let sqlPas = res.data.results[0].para_03;
          dataToSql.para_04 = res.data.results[0].para_04;
          dataToSql.para_05 = res.data.results[0].para_05;

          if (dataToSql.para_03 == sqlPas) {
            console.log("登录成功");
            // 将用户信息持久化
            store.commit("addUserName", dataToSql.para_02);
            store.commit("addUserToken", dataToSql.para_04);
            store.commit("addUserLevel", dataToSql.para_05);
            // 关闭窗口
            opeLloginWindow("close");
          } else alert("密码错误");
        }
      });
    };

    function goOrder() {
      $router.push({
        name: "Order",
      });
    }

    function goLogin() {
      $router.push({
        name: "Login",
      });
    }

    function goPhotos() {
      $router.push({
        name: "Photos",
      });
    }

    function goUsers() {
      $router.push({
        name: "Users",
      });
    }

    function goUsersList() {
      $router.push({
        name: "UsersList",
      });
    }

    function goMyRoom() {
      $router.push({
        name: "MyRoom",
      });
    }

    function go3DList() {
      $router.push({
        name: "ThreeList",
      });
    }

    function goSearch() {
      $router.push({
        name: "Search",
      });
    }

    function goAddData() {
      $router.push({
        name: "AddData",
      });
    }

    function goProtectedInsect() {
      $router.push({
        name: "ProtectedInsect",
      });
    }
    function goProtectedInsectUpload() {
      $router.push({
        name: "ProtectedInsectUpload",
      });
    }
    function goKeyInsect() {
      $router.push({
        name: "KeyInsect",
      });
    }
    const goPrice = () => {
      $router.push({
        name: "Price",
      });
    };

    return {
      axiosToSql,
      dataToSql,
      opeLloginWindow,
      goOrder,
      goLogin,
      goPhotos,
      goUsers,
      goUsersList,
      goMyRoom,
      go3DList,
      goSearch,
      goAddData,
      goProtectedInsect,
      goProtectedInsectUpload,
      goKeyInsect,
      goPrice,
      login,
      baseImg,
    };
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.pcOnly {
  @media only screen and (max-width: 1000px) {
    display: none;
  }
}
.bottom {
  background-color: rgb(220, 220, 220);
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  a {
    text-decoration: none;
    color: gray;
  }
}
.loginWindow {
  display: none;
  .box {
    border: 3px solid gray;
    position: fixed;
    width: 500px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 90;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn_box {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin: 30px;
      .btn {
        border: 2px solid gray;
        padding: 10px 20px;
        border-radius: 5px;
      }
      .btn:hover {
        background-color: skyblue;
      }
    }
    input {
      margin: 10px;
      border: 2px solid rgb(160, 160, 160);
      padding: 5px;
      border-radius: 5px;
    }
  }
}
.beiAnHao {
  width: 100%;
  /* background-color: #ec7d7d; */
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.beiAnHao a {
  text-decoration: none;
  color: gray;
  font-size: 1rem;
}
.head {
  position: fixed;
  background-color: white;
  display: flex;
  /* border: 1px solid black; */
  width: 100%;
  height: 80px;
  -webkit-box-shadow: 3px 3px 10px #888888;
  box-shadow: 3px 3px 5px #888888;
  justify-content: center;
  z-index: 90;
  top: 0;
  left: 0;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
  .head_box01 {
    display: flex;
    align-items: center;
    height: 100%;
    //  border: 1px solid black;
    width: 100%;
    padding: 0 100px;
    justify-content: space-between;
    .login_box {
      display: flex;
      margin: 0 0 0 0;

      .login {
        @media only screen and (max-width: 1000px) {
          position: fixed;
          bottom: 0;
          z-index: 99;
          background-color: rgb(255, 255, 255);
          width: 100vw;
          left: 0;
          text-align: center;
        }
      }
    }
    .title_app {
      margin-right: 30px;
      text-align: center;
      font-weight: bold;
      font-size: 25px;
      color: rgb(176, 176, 218);
    }
    .login:hover {
      color: skyblue;
    }
  }
}

.head_box02 {
  display: flex;
  align-items: center;
  height: 100%;
  /* border: 1px solid black; */
  width: 20%;
  justify-content: flex-end;
}
.container {
  padding: 100px 2% 50px 2%;
  /* border: 1px solid black; */
  /* top:70px; */
}

.mobile_bottombar {
  position: fixed;
  background-color: white;
  display: flex;
  // border: 1px solid black;
  width: 100vw;
  height: 50px;
  -webkit-box-shadow: 3px 3px 10px #888888;
  box-shadow: -3px -3px 5px #888888;
  justify-content: space-around;
  align-items: center;
  z-index: 99;
  top: 100vh;
  left: 0;
  transform: translate(0, -100%);
  padding: 0 1rem;
}
.mobile_head {
  position: fixed;
  background-color: white;
  display: flex;
  /* border: 1px solid black; */
  width: 100vw;
  height: 50px;
  -webkit-box-shadow: 3px 3px 10px #888888;
  box-shadow: 3px 3px 5px #888888;
  justify-content: space-around;
  align-items: center;
  z-index: 90;
  top: 0;
  left: 0;
  // padding: 0 1rem;
}
</style>
