import { createRouter, createWebHashHistory } from "vue-router";

const Price = () => import("@/components/Price");
const Stocks = () => import("@/components/Stocks");
const PriceRecordInsect = () => import("@/components/PriceRecordInsect");
const Order = () => import("@/components/Order");
const Login = () => import("@/components/Login");
const PriceRecord = () => import("@/components/PriceRecord");
const ThreeList = () => import("@/components/ThreeList");
const UniversalData = () => import("@/components/UniversalData");
const InsectLable = () => import("@/components/InsectLable");
const GoodOrder = () => import("@/components/GoodOrder");
const UserData = () => import("@/components/UserData");
const ImgGallery = () => import("@/components/ImgGallery");
const ImgGalleryNew = () => import("@/components/ImgGalleryNew");
const ImgGalleryKilala = () => import("@/components/ImgGalleryKilala");
const DownLoad = () => import("@/components/DownLoad");
const TagLable = () => import("@/components/TagLable");
const MonoPhotos = () => import("@/components/MonoPhotos");
const PdfDetail = () => import("@/components/PdfDetail");
const BookList = () => import("@/components/BookList");
const PdfList = () => import("@/components/PdfList");
const InsectSpecies = () => import("@/components/InsectSpecies");
const LiquidSpecimen = () => import("@/components/LiquidSpecimen");
const DetailPage = () => import("@/components/DetailPage");
const CardLabel = () => import("@/components/CardLabel");
const CardLabelBeast = () => import("@/components/CardLabelBeast");
const CardLabelFish = () => import("@/components/CardLabelFish");
const LayoutDesign = () => import("@/components/LayoutDesign");
const PrintPage = () => import("@/components/PrintPage");
const SelectSpecimen = () => import("@/components/SelectSpecimen");
const HandBook = () => import("@/components/HandBook");
const CardLabelMedicine = () => import("@/components/CardLabelMedicine");
const ThreeData = () => import("@/components/ThreeData");
const DeletedData = () => import("@/components/DeletedData");
const CardLabelButterfly = () => import("@/components/CardLabelButterfly");
const UpdateData = () => import("@/components/UpdateData");
const InsertData = () => import("@/components/InsertData");
const CoverDesign = () => import("@/components/CoverDesign");
const LayoutDeisignTest = () => import("@/components/LayoutDeisignTest");
const CoverDesignTwo = () => import("@/components/CoverDesignTwo");
const CoverDesignSub = () => import("@/components/CoverDesignSub");
const CoverDesignA = () => import("@/components/CoverDesignA");
const getLable = () => import("@/components/getLable");
const getLableA = () => import("@/components/getLableA");
const getLableB = () => import("@/components/getLableB");
const getLableC = () => import("@/components/getLableC");
const PrintCer = () => import("@/components/PrintCer");
const PrintImgA = () => import("@/components/PrintImgA");
const PrintImgB = () => import("@/components/PrintImgB");
const PrintImgC = () => import("@/components/PrintImgC");
const PrintImgD = () => import("@/components/PrintImgD");
const PrintImgE = () => import("@/components/PrintImgE");
const getLableD = () => import("@/components/getLableD");
const PrintImgF = () => import("@/components/PrintImgF");
const PrintImgG = () => import("@/components/PrintImgG");
const PrintImgH = () => import("@/components/PrintImgH");
const PrintImgI = () => import("@/components/PrintImgI");
const PrintImgJ = () => import("@/components/PrintImgJ");
const PrintImgK = () => import("@/components/PrintImgK");
const PrintImgL = () => import("@/components/PrintImgL");
const PriceRecordProject = () => import("@/components/PriceRecordProject");
const CoverDesignVera = () => import("@/components/CoverDesignVera");
const WangCun = () => import("@/components/WangCun");
const WangCunList = () => import("@/components/WangCunList");
const CreateCode = () => import("@/components/CreateCode");
const LackIndex = () => import("@/components/LackIndex");
const LackIndexBuy = () => import("@/components/LackIndexBuy");
const LackIndexHave = () => import("@/components/LackIndexHave");
// 文件下载 网盘数据
const DownloadList = () => import("@/components/DownloadList");
// 下载图片
const DownloadImg = () => import("@/components/DownloadImg");
const CardLabelCertificate = () =>
  import("@/components/CardLabelCertificateNew");
const CardLabelCertificateNew = () =>
  import("@/components/CardLabelCertificate");
const TextLayout = () => import("@/components/TextLayout");
const CardLabelMaterialShop = () =>
  import("@/components/CardLabelMaterialShop");
const CardLabelMedicineText = () =>
  import("@/components/CardLabelMedicineText");

const routes = [
  {
    path: "/",
    redirect: "/Login",
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },

  {
    path: "/DownloadList",
    name: "DownloadList",
    component: DownloadList,
  },
  {
    path: "/DownloadImg",
    name: "DownloadImg",
    component: DownloadImg,
  },
  {
    path: "/WangCunList",
    name: "WangCunList",
    component: WangCunList,
  },
  {
    path: "/WangCun",
    name: "WangCun",
    component: WangCun,
  },
  {
    path: "/PrintImgA",
    name: "PrintImgA",
    component: PrintImgA,
  },
  {
    path: "/PrintImgB",
    name: "PrintImgB",
    component: PrintImgB,
  },
  {
    path: "/PrintImgC",
    name: "PrintImgC",
    component: PrintImgC,
  },
  {
    path: "/PrintImgD",
    name: "PrintImgD",
    component: PrintImgD,
  },
  {
    path: "/PrintImgE",
    name: "PrintImgE",
    component: PrintImgE,
  },
  {
    path: "/PrintImgF",
    name: "PrintImgF",
    component: PrintImgF,
  },
  {
    path: "/PrintImgG",
    name: "PrintImgG",
    component: PrintImgG,
  },
  {
    path: "/PrintImgH",
    name: "PrintImgH",
    component: PrintImgH,
  },
  {
    path: "/PrintImgI",
    name: "PrintImgI",
    component: PrintImgI,
  },
  {
    path: "/PrintImgJ",
    name: "PrintImgJ",
    component: PrintImgJ,
  },
  {
    path: "/PrintImgK",
    name: "PrintImgK",
    component: PrintImgK,
  },
  {
    path: "/PrintImgL",
    name: "PrintImgL",
    component: PrintImgL,
  },
  {
    path: "/PriceRecordProject",
    name: "PriceRecordProject",
    component: PriceRecordProject,
  },
  {
    path: "/CoverDesignSub",
    name: "CoverDesignSub",
    component: CoverDesignSub,
  },
  {
    path: "/CoverDesignVera",
    name: "CoverDesignVera",
    component: CoverDesignVera,
  },
  {
    path: "/CoverDesignA",
    name: "CoverDesignA",
    component: CoverDesignA,
  },
  {
    path: "/PrintCer",
    name: "PrintCer",
    component: PrintCer,
  },
  {
    path: "/CoverDesign",
    name: "CoverDesign",
    component: CoverDesign,
  },
  {
    path: "/getLable",
    name: "getLable",
    component: getLable,
  },
  {
    path: "/getLableA",
    name: "getLableA",
    component: getLableA,
  },
  {
    path: "/getLableB",
    name: "getLableB",
    component: getLableB,
  },
  {
    path: "/getLableC",
    name: "getLableC",
    component: getLableC,
  },
  {
    path: "/getLableD",
    name: "getLableD",
    component: getLableD,
  },
  {
    path: "/CoverDesignTwo",
    name: "CoverDesignTwo",
    component: CoverDesignTwo,
  },
  {
    path: "/TextLayout",
    name: "TextLayout",
    component: TextLayout,
  },
  {
    path: "/LayoutDeisignTest",
    name: "LayoutDeisignTest",
    component: LayoutDeisignTest,
  },
  {
    path: "/CardLabelCertificateNew",
    name: "CardLabelCertificateNew",
    component: CardLabelCertificateNew,
  },
  {
    path: "/CardLabelCertificate",
    name: "CardLabelCertificate",
    component: CardLabelCertificate,
  },
  {
    path: "/InsertData",
    name: "InsertData",
    component: InsertData,
  },

  {
    path: "/UpdateData",
    name: "UpdateData",
    component: UpdateData,
  },
  {
    path: "/DeletedData",
    name: "DeletedData",
    component: DeletedData,
  },
  {
    path: "/ThreeData",
    name: "ThreeData",
    component: ThreeData,
  },
  {
    path: "/CardLabelMaterialShop",
    name: "CardLabelMaterialShop",
    component: CardLabelMaterialShop,
  },
  {
    path: "/CardLabelMedicineText",
    name: "CardLabelMedicineText",
    component: CardLabelMedicineText,
  },
  {
    path: "/CardLabelMedicine",
    name: "CardLabelMedicine",
    component: CardLabelMedicine,
  },
  {
    path: "/PrintPage",
    name: "PrintPage",
    component: PrintPage,
  },
  {
    path: "/HandBook",
    name: "HandBook",
    component: HandBook,
  },
  {
    path: "/CardLabel",
    name: "CardLabel",
    component: CardLabel,
  },
  {
    path: "/CardLabelFish",
    name: "CardLabelFish",
    component: CardLabelFish,
  },
  {
    path: "/CardLabelBeast",
    name: "CardLabelBeast",
    component: CardLabelBeast,
  },
  {
    path: "/CardLabelButterfly",
    name: "CardLabelButterfly",
    component: CardLabelButterfly,
  },
  {
    path: "/InsectLable",
    name: "InsectLable",
    component: InsectLable,
  },
  {
    path: "/DetailPage",
    name: "DetailPage",
    component: DetailPage,
  },

  {
    path: "/Price",
    name: "Price",
    component: Price,
    children: [
      {
        path: "LackIndexHave",
        name: "LackIndexHave",
        component: LackIndexHave,
      },
      {
        path: "LackIndexBuy",
        name: "LackIndexBuy",
        component: LackIndexBuy,
      },
      {
        path: "LackIndex",
        name: "LackIndex",
        component: LackIndex,
      },
      {
        path: "CreateCode",
        name: "CreateCode",
        component: CreateCode,
      },
      {
        path: "SelectSpecimen",
        name: "SelectSpecimen",
        component: SelectSpecimen,
      },
      {
        path: "LayoutDesign",
        name: "LayoutDesign",
        component: LayoutDesign,
      },
      {
        path: "LiquidSpecimen",
        name: "LiquidSpecimen",
        component: LiquidSpecimen,
      },
      {
        path: "InsectSpecies",
        name: "InsectSpecies",
        component: InsectSpecies,
      },
      {
        path: "BookList",
        name: "BookList",
        component: BookList,
      },
      {
        path: "PdfList",
        name: "PdfList",
        component: PdfList,
      },
      {
        path: "PdfDetail",
        name: "PdfDetail",
        component: PdfDetail,
      },
      {
        path: "UniversalData",
        name: "UniversalData",
        component: UniversalData,
      },

      {
        path: "UserData",
        name: "UserData",
        component: UserData,
      },
      {
        path: "GoodOrder",
        name: "GoodOrder",
        component: GoodOrder,
      },
      {
        path: "PriceRecordInsect",
        name: "PriceRecordInsect",
        component: PriceRecordInsect,
      },
      {
        path: "Stocks",
        name: "Stocks",
        component: Stocks,
      },
      {
        path: "Order",
        name: "Order",
        component: Order,
      },
      {
        path: "PriceRecord",
        name: "PriceRecord",
        component: PriceRecord,
      },
      {
        path: "ThreeList",
        name: "ThreeList",
        component: ThreeList,
      },
      {
        path: "ImgGallery",
        name: "ImgGallery",
        component: ImgGallery,
      },
      {
        path: "DownLoad",
        name: "DownLoad",
        component: DownLoad,
      },
      {
        path: "TagLable",
        name: "TagLable",
        component: TagLable,
      },
      {
        path: "MonoPhotos",
        name: "MonoPhotos",
        component: MonoPhotos,
      },
      {
        path: "ImgGalleryNew",
        name: "ImgGalleryNew",
        component: ImgGalleryNew,
      },
      {
        path: "ImgGalleryKilala",
        name: "ImgGalleryKilala",
        component: ImgGalleryKilala,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
